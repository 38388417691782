import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const style = {
  color: "#2498fa",
}

const goBack = e => {
  e.preventDefault()
  window.history.back()
}
const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" lang="en" />
    <div className="jumbotron" style={{ margin: "100px 0 0 0", "padding-top": "0px" }}>
      <center>
        <h1 style={{ ...style, "font-size": "130px" }}>404</h1>
        <h2>Page Not Found.</h2>
        <p>
          It looks like nothing was found at this location. Maybe try a search?
        </p>
        <p>
          <Link style={style} to="/">
            Home Page
          </Link>{" "}
          or go back to{" "}
          <a style={style} href="/#" onClick={goBack}>
            Previous Page
          </a>
        </p>
      </center>
    </div>
  </Layout>
)

export default NotFoundPage
